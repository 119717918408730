<script>
import DefaultMixin from "@/components/leiloes/mixin"

export default {
  name: 'ApresentacaoTelaoSeletor',
  inject: ['page', 'erplayout'],
  mixins: [DefaultMixin],
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.page.setActive('telao')
    document.querySelector('.u-erp-layout-container').classList.add('leilao-telao-seletor')
  },
  destroyed() {
    document.querySelector('.u-erp-layout-container').classList.remove('leilao-telao-seletor')
  },
  methods: {},
  meta: {
    title: 'Apresentação do leilão em Telão',
    name: 'Apresentação do leilão em Telão'
  }
}
</script>

<template>
  <div class="seletor-apresentacao-telao">
    Embora o leiloeiro possa utilizar qualquer tela para tvs e telão durante o leilão, o sistema possui telas exclusivas
    para este fim:
    <div class="m-t-lg flex">
      <div @click="$router.push({name: 'leiloes.apresentacao-telao-black1'})" class="telao-template-item">
        <div class="image"><img src="@/assets/images/telao-black-1.jpg"/></div>
        <div class="legend">
          <div class="font-avenir font-bold">Tema Black 1</div>
          <div class="m-t-sm">Tema de cor escura com contrastes nas informações, recomendado para telões.</div>
        </div>
      </div>
      <div @click="$router.push({name: 'leiloes.apresentacao-telao-black2'})" class="telao-template-item">
        <div class="image"><img src="@/assets/images/telao-black2.jpg"/></div>
        <div class="legend">
          <div class="font-avenir font-bold">Tema Black 2</div>
          <div class="m-t-sm">Tema de cor escura com contrastes nas informações + últimos lances + logo comitente + logo leiloeiro.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./assets/seletor.styl" lang="stylus"/>
